.AssignLoadFormSec{
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
}
.AssignLoadFormSec ::placeholder{
    color: #8F9FB4;
    opacity: 0.65;
}
.bb1pxLight{
    height: 2px;
    width: 100%;
    background: #8F9FB4;
    opacity: 0.3;
}
.attachFile {
    max-width: 200px;
    margin: auto;
}
.attachFile .title {
    font-size: 13px;
}
.attachFile input[type="file"] {
    display: none;
}
.attachFile label{
    cursor: pointer;
    width: 100%;
    height: 150px;
    border: 1px dashed #a5b4c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    
}
.attachFile label img {
    max-width: 100%;
    max-height: 100%;
}
.opacity1 {
    opacity: 1 !important;
}
