.paymentmethod {
  height: 70px;
  border-radius: 10px;
  margin-top: 20px;
  /* width: 300px; */
  /* display: flex; */
  /* flex-direction: column; */
}

.inputadd {
  margin-top: 20px;
}
.addbutton {
  height: 40px;
  width: 50px;
  margin-top: 20px;
}
.paymentmethodadd {
  height: 70px;
  border-radius: 10px;
  margin-top: 20px;
  /* width: 300px; */
  display: flex;
  /* flex-direction: row; */
}
.delete {
  margin-top: 5px;
  margin-right: 10px;
  float: right;
  height: 30px;
  width: 30px;
}
.selection {
  /* margin-left: 30px; */
  width: 100%;
}

.selectionProduct {
  /* margin-left: 20px; */
  /* width: 400px; */
  margin-bottom: 20px;
}

.methodcontainer {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.productlist {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.productcontainer {
  border-radius: 10px;
  margin-top: 20px;
  width: 200px;
}

.NozelContainer {
  /* height: 100px; */
  border-radius: 10px;
  margin-top: 20px;
  /* width: 500px; */
  /* display: flex; */
  /* flex-direction: column; */
}
.input1 {
  /* margin-left: 20px; */
  /* width: 400px; */
  margin-bottom: 20px;
  /* background-color: aqua; */
}

.input2 {
  /* margin-left: 20px; */
  /* width: 400px; */
  margin-bottom: 10px;
}
