.assignLoadBtn{
    width: 87px;
    height: 87px;
    background-image: linear-gradient(to right, rgb(255 0 247), rgb(82 12 90));
    display: flex;justify-content: center;
    align-items: center;
   
    border-radius: 50%;
    box-shadow: 0 10px 16px -8px black;
    margin: auto;

}
.assignLoadBtn:hover{
    opacity: 0.7;

}
.assignNewLoadPage .abcTr{
    font-size: 22px;
    text-align: center;
}
.usdot{
    color: #8F9FB4;
    font-size: 22px;
    text-align: center;

}
.assignNewLoadPage .assignLoadLink{
    color: #8F9FB4;
    font-size: 16px;
    font-weight: bold;
    opacity: 0.65;
    text-decoration: none;
    transition: all 0.2s;
    
}
.assignNewLoadPage .assignLoadLink:hover{
    opacity: 1;
    border-bottom: 4px solid;
    text-decoration: none;
}
.assignNewLoadPage .assignLoadLink.active{
    opacity: 1;
    border-bottom: 4px solid;
}
.assignNewLoadPage .assignLoadLinkDiv{
    border-top: 1px solid #d1dcea;
}
.addNewLoadHeading{
    color: #8F9FB4;
    opacity: 0.65;
    font-size: 22px;
    
}
.addNewLoadHeadingDiv{
    margin-top: 20%;
    text-align: center;
}
