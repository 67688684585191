.globalModal {
    /* background-color: aqua;
     */
     position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1111;
}
.spinnercolor
{
    color: #FCD32B;
}
.globalModal .loader {
    width: 100%;
    max-width: 400px;
    padding: 40px 0;
    text-align: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 20px;
    position: fixed;
}
.pay-block {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.pay-block span {
    font-weight: 600;
}

.pay-block span span {
    font-weight: 700;
}
