.jc-sb{
    justify-content: space-between;
}
.LoadInfo{
    background: white;
    border-radius: 10px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
}
.LoadInfo .city{
    font-size: 22px;
}
.LoadInfo .state{
    font-size: 16px;
    color: #8F9FB4;
    opacity: 0.65;
}
.addNewLoadPage .dateText{
    color: #8F9FB4;
    opacity: 0.65;
    font-size: 12px;
}
.addNewLoadPage .date{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
}
.LoadInfo .completed{
    color: #99B42A;
    font-size: 12px;
}
.LoadInfo .to{
    font-size: 12px;
}
.LoadInfo .destinationDiv{
    padding: 10px 20px;
}
.LoadInfo .dateDiv{
    padding: 10px 20px;
    padding-top: 0 !important;
    border-bottom: 1px solid #e9ebec;
}
.LoadInfo .amountDiv{
    padding: 15px 20px;
}
.addNewLoadPage .loadHeading{
    font-size: 18px;
    color: #333333;
    font-weight: bold;
}

/* 2nd col map */
.mapContent .statusText{
    color: #8F9FB4;
    opacity: 0.65;
    font-size: 12px;
}
.mapContent .status{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    
}
.addNewLoadPage .mapContent{
    background: white;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
    margin-top: -120px;
    border-radius: 10px;
}
.addNewLoadPage .secondCol .loadDetail{
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;

}
.fs-22{
    font-size: 22px;
}
.dateDiv .loadPrice{
    margin-right: 25%;
}
.addNewLoadPage .orderAccept-col{
    background-color: white;
    border-radius: 10px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
}
.addNewLoadPage .orderAccept-col h6{
    color: #8F9FB4;
    font-size: 14px;
    opacity: 0.65;
    font-weight: bold;
}
.addNewLoadPage .orderAccept-col h5{
    font-size: 22px;
}
.addNewLoadPage .orderAccept-col p{
    font-size: 14px;
    color: #8F9FB4;
    opacity: 0.65;
}
.addNewLoadPage .orderAccept-col .lineh20{
    background: #B5B7BE;
    width: 2px;
    height: 17px;
    margin: auto;
}
.addNewLoadPage .orderAccept-col .lineh25{
    background: #B5B7BE;
    width: 2px;
    height: 25px;
    margin: auto;
}
.addNewLoadPage .orderAccept-col .lineh50{
    background: #B5B7BE;
    width: 2px;
    height: 55px;
    margin: auto;
}
@media(max-width:1200px){
    .addNewLoadPage .orderAccept-col-sm{
        background-color: white;
        border-radius: 10px;
        box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
    }
    .addNewLoadPage .orderAccept-col{
        
        background-color: transparent !important;
        border-radius: 10px;
        box-shadow: none !important;
        margin-top: 20px;
    }
}
