.chat-card{
    background-color: white;
    border-radius: 10px;
    padding-bottom: 20px;
    box-shadow: 0 .5rem 1rem  rgba(0,0,0,.1)!important;
}
.chat-card .chat-senderDiv{
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e9e9e9;
}
.chat-card .chat-senderDiv .onlineSign{
    width: 7px;
    height: 7px;
    background-color: rgb(54, 185, 54);
    border-radius: 50%;
    margin-left: 15px;
}
.chat-card .chat-senderDiv .dropdown-toggle::after{
  display: none !important;
}
.ai-center{
    align-items: center;
}
.chatDiv{
    max-height: 500px;
    overflow-y: scroll;
}
.chatDiv::-webkit-scrollbar {
    width: 10px;
}
.chatDiv::-webkit-scrollbar-track {
    background-color: white;
}
.chatDiv::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0);
}
.chatDiv .userName{
    text-align: center;
    margin-top: 30px;
    border-top: 1px solid rgba(143, 159, 180, .5);
}
.chatDiv h6{
    display: inline-block;
    text-align: center;
    padding: 2px 20px;
    background-color: white;
    position: relative;
    top: -15px;
    color: rgba(143, 159, 180, .5);
}
.chat-card .chatDiv .chatMain{
    padding-left: 10px;
    padding-right: 10px;
}
.chat-card .chatDiv .revieveMsg{
    display: flex;
    padding: 15px;
    background-color: #E9ECF0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    max-width: 270px;
    width: max-content;
    justify-content: space-between;
    margin-bottom: 5px;
}
.chat-card .chatDiv .sendMsg{
    display: flex;
    padding: 15px;
    background-color: #E7EFFC;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    max-width: 270px;
    justify-content: space-between;
    margin-left: auto;
    margin-bottom: 5px;
    width: max-content;

}
.chat-card .time{
    color: rgba(0, 0, 0, .42);
    font-size: 14px;
    margin-left: 10px;

}
.chat-card .masgWriteDiv{
    background-color: #E9ECF0;
    border-radius: 5px;
    padding: 7px !important;
    align-items: center;
    margin-bottom: 20px;

}
.chat-card .masgWriteDiv input[type=file] {
    display: none;
}
.chat-card .masgWriteDiv input{
    background-color: #E9ECF0;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    border: transparent;
}
.chat-card .masgWriteDiv .sendBtn{
    padding: 8px 20px;
}
.chat-card .masgWriteDiv input:focus,.chat-card .masgWriteDiv input:active{
    outline: none;
}
.chat-card .receiverTyping {
    padding: 10px;
    text-align: end;
}
.chat-card .receiverTyping .prDiv{
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.chat-card .receiverTyping .typingAnimation{
    width: 25px;
    height: 25px;
    margin-left: 3px;
}
.chat-card .chatUserLogo {
    text-align: center;
    padding-top: 50px;
}
.chat-card .chatUserInfo {
    text-align: center;
    margin: 20px 0;
}
.chat-card .chatUserInfo h6{
    font-weight: 700;
}
.chat-card .chatUserInfo p{
    color: rgba(143, 159, 180, .5);
    margin-bottom: 0;
    font-size: 13px;
}
.chat-card .chatUserLogo img{
    width: 110px;
    height: 110px;
    border-radius: 50%;
}
.chat-card .chatStatus{
    text-align: center;
    font-weight: 700;
}
.chat-card .callDiv{
    padding: 20px 0;
    text-align: center;
    border-bottom: 2px solid #e9e9e9;
}
.chat-card .callDiv a{
   color: #A2B6FF;
   font-size: 14px;
}
.chat-card .callDiv a:hover{
  opacity: 0.7;
}
.chat-card .ChatAttacment{
    padding: 15px;
}
.chat-card .ChatAttacment .attachmentDiv{
    text-align: center;
}
.chat-card .ChatAttacment label{
    background-color: #E2E8FF;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 5px;
}
.chat-card .ChatAttacment input[type=file] {
    display: none;
}
.chat-card .ChatAttacment .veiwAllBtn{
    color: #A2B6FF;
    border-radius: 5px;
    border: 1px solid #A2B6FF;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 700;
}
.chat-card .callDiv .microPhone{
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #D3D5D7;
    border-radius: 50%;
}
.chat-card .callDiv .microPhone img{
    width: 50%;
    height: 50%;
}
@media screen and (max-width: 1270px) and (min-width: 1215px){
    .chat-card .ChatAttacment label{
        padding: 10px;
    }
}
@media screen and (max-width: 1214px) and (min-width: 1200px){
    .chat-card .ChatAttacment label{
        padding: 9px;
    }
}
